<template>
  <section class="dynamic-banner">
    <h2 class="visually-hidden">
      {{ info?.name }}
    </h2>

    <template v-if="info?.button">
      <CustomImage
        v-if="image.id"
        :image-id="image.id"
        :aspect-ratio="image.ratio"
        :loading="loading"
        :width="image.sizes[0]"
        :height="image.sizes[1]"
        :alt="info?.name"
      />
      <Button
        :to="buttonLink.to"
        :href="buttonLink.href"
        class="dynamic-banner__button"
        :theme="themeButton"
        :class="{
          [`dynamic-banner__button--${info?.button.location}`]: true,
          [`dynamic-banner__button--${info?.button.type}`]: true,
        }"
      >
        {{ info?.button.text }}
      </Button>
    </template>
    <CustomLink
      v-else
      :to="info?.url"
    >
      <CustomImage
        v-if="image.id"
        :media="mediaImage"
        :image-id="image.id"
        :aspect-ratio="image.ratio"
        :width="image.sizes[0]"
        :height="image.sizes[1]"
        :alt="info?.name"
      />
    </CustomLink>
  </section>
</template>

<script>
import Button from '@/components/elements/Button';
import CustomLink from '~/components/elements/CustomLink';
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'DynamicBanner',
  components: {
    CustomImage,
    CustomLink,
    Button,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    sizes: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    const defaultRatio = {
      mobile: '97.78%',
      tablet: '27.08%',
      pc: '27.08%',
    }

    const defaultSizes = {
      mobile: {
        width: 360,
        height: 352,
      },
      tablet: {
        width: 1200,
        height: 325,
      },
      pc: {
        width: 1200,
        height: 325,
      },
    }

    const mediaImage = [
      {
        id: this.info?.mobileImageId,
        width: defaultSizes.mobile.width,
        height: defaultSizes.mobile.height,
        media: '(max-width:768px)',
      },
      {
        id: this.info?.tabletImageId,
        width: defaultSizes.tablet.width,
        height: defaultSizes.tablet.height,
        media: '(max-width:1023px)',
      },
    ]

    return {
      mediaImage: mediaImage.filter(item => item.id),

      bannerRatio: {},
      bannerSizes: defaultSizes,
      defaultRatio,
      defaultSizes,

      image: {
        id: this.info?.desktopImageId,
        sizes: [defaultSizes.pc.width, defaultSizes.pc.height],
        ratio: defaultRatio.pc,
      },
    }
  },
  computed: {
    themeButton() {
      switch(this.info?.button.type) {
        case 'white':
          return 'inversion';
        case 'outline_black':
          return 'tertiary';
        default:
          return '';
      }
    },
    isExternalLink() {
      return typeof this.info?.url === 'string' && this.info?.url.startsWith('http')
    },
    buttonLink() {
      return {
        to: this.isExternalLink ? '' : this.info?.url,
        href: this.isExternalLink ? this.info?.url : '',
      }
    },
    mqIsMobileXs() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile';
    },
    mqIsTablet() {
      return this.$mq === 'tablet';
    },
  },
  watch: {
    '$mq': {
      handler(value) {
        switch (value) {
          case 'mobileXs':
          case 'mobile':
            if (this.mediaImage.length) {
              this.setMediaSizes(this.info?.mobileImageId, {
                width: this.bannerSizes.mobile.width,
                height: this.bannerSizes.mobile.height,
              })

              this.image.sizes = [ this.bannerSizes.mobile.width, this.bannerSizes.mobile.height ]
              this.image.ratio = this.bannerRatio.mobile
            }
            break;
          case 'tablet':
            if (this.mediaImage.length) {
              this.setMediaSizes(this.info?.tabletImageId, {
                width: this.bannerSizes.tablet.width,
                height: this.bannerSizes.tablet.height,
              })

              this.image.sizes = [ this.bannerSizes.tablet.width, this.bannerSizes.tablet.height ]
              this.image.ratio = this.bannerRatio.tablet
            }
            break;
          default:
            this.image.sizes = [ this.bannerSizes.pc.width, this.bannerSizes.pc.height ]
            this.image.ratio = this.bannerRatio.pc
        }
      },
      immediate: true,
    },
  },
  created() {
    Object.assign(this.bannerRatio, this.defaultRatio, this.ratio)
    Object.assign(this.bannerSizes, this.defaultSizes, this.sizes )
  },
  beforeMount() {
    if (this.mqIsTablet) {
      this.image.sizes = [ this.bannerSizes.tablet.width, this.bannerSizes.tablet.height ]
      this.image.ratio = this.bannerRatio.tablet

      this.setMediaSizes(this.info?.tabletImageId, {
        width: this.bannerSizes.mobile.width,
        height: this.bannerSizes.mobile.height,
      })
    } else if (this.mqIsMobileXs) {
      this.image.sizes = [ this.bannerSizes.mobile.width, this.bannerSizes.mobile.height ]
      this.image.ratio = this.bannerRatio.mobile

      this.setMediaSizes(this.info?.mobileImageId, {
        width: this.bannerSizes.tablet.width,
        height: this.bannerSizes.tablet.height,
      })
    } else {
      this.image.sizes = [ this.bannerSizes.pc.width, this.bannerSizes.pc.height ]
      this.image.ratio = this.bannerRatio.pc
    }
  },
  methods: {
    setMediaSizes(id, sizes) {
      const mediaId = this.mediaImage.findIndex(item => item.id === id)

      if (mediaId !== -1) {
        this.$set(this.mediaImage[mediaId], 'width', sizes.width)
        this.$set(this.mediaImage[mediaId], 'height', sizes.height)
      }
    },
  },
}
</script>
